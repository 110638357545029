


















































import { Component, Prop } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  inheritAttrs: false,
})
export default class StaticBannerWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: null })
  private readonly desktopImage!: { path: string; id: number };

  @Prop({ required: false, default: null })
  private readonly tabletImage!: { path: string; id: number };

  @Prop({ required: false, default: null })
  private readonly mobileImage!: { path: string; id: number };

  @Prop({ required: false, default: null })
  private readonly url!: string;

  private hasError = false;

  private get banner(): { image: { path: string; id: number }; url: string } {
    this.hasError = false;
    // eslint-disable-next-line no-nested-ternary
    return this.isDesktop
      ? {
        image: { ...this.desktopImage },
        url: this.url as string,
      }
      : this.isTablet
        ? {
          image: { ...this.tabletImage },
          url: this.url as string,
        }
        : {
          image: { ...this.mobileImage },
          url: this.url as string,
        };
  }

  created(): void {
    this.setDataConfig();
  }
}
